import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button } from "../src/gatsby-theme-docz/components/Button/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "unternehmen"
    }}>{`Unternehmen`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Kunde`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Flughafen Leipzig/Halle (Landebahnbeob.-Haus)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Kraftwerk Schkopau`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Druckluft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Landesgartenschau Oschatz 2006 GmbH, Blumenhalle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/L/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TELECOM Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BANNER-Batterien, Mutzschen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HEZINGER Aluminiumwerk Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EDELHOFF GmbH Trebsen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`135 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ELSNER Bau GmbH Grimma`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SCHWÖRER GmbH + Co, Leipzig, Wohn- u.Geschäftshaus, Leipzig, Inselstraße`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`450 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BUSSE GmbH, Baalsdorf`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BioChem, Gerichshein, Laborgebäude`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fleischerei Zscherben`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 T€`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Legende: H=Heizung, S=Sanitär, L=Lüftungsinstallation, K=Kälte- bzw. Klimainstallationen, MSR=Regeltechnische Anlagen`}</strong></p>
    <Button mdxType="Button" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      